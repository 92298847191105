import React, { Component } from 'react';

class PersonalInfo extends Component {
  render() {
    return (
      <div>
        <span className="name">Swar Shah</span>
        <h5 className="title">Software Developer in Canada</h5>
      </div>
    );
  }
}

export default PersonalInfo;
